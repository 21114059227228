import React from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import './App.css';  // Import your styles
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFootballBall } from '@fortawesome/free-solid-svg-icons';
import GameList from './GameList';
import About from './About';
import Home from './Home';

// client setup
import HistoricalGames from './HistoricalGames';
import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';

const graphqlUrl = process.env.REACT_APP_GRAPHQL_URL || '';

const client = new ApolloClient({
  uri: `${graphqlUrl}/graphql/`,
  cache: new InMemoryCache()
});

if (process.env.NODE_ENV !== "production") {  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}
const App = () => {
  return (
    <ApolloProvider client={client}>
      <Router>
        <div className="container">
          <header className="header">
            <FontAwesomeIcon icon={faFootballBall} className="football-icon" size="2x" />
            <h1>RICK'S PICKS</h1>
            <nav>
              <Link to="/">Home</Link>
              <Link to="/about">About</Link>
              <Link to="/historical-games">Historical Games</Link>
            </nav>
          </header>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/historical-games" element={<HistoricalGames />} />
          </Routes>
        </div>
      </Router>
    </ApolloProvider>
  );
};

export default App;