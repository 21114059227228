import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFootballBall } from '@fortawesome/free-solid-svg-icons';

const GameCard = ({ game }) => {
  console.log('Is favorite covered:', game.favoriteCovered);  // Debug line
  return (
    <div className="game-card">
      <h3>{game.homeTeam} vs {game.awayTeam}</h3>
      <p>Game Date: {game.startDate}</p>
      <p>Game Completed: {game.completed}</p>
      <p>Home Team: {game.homeTeam}</p>
      <p>Home Score: {game.homePoints}</p>
      <p>Away Score: {game.awayPoints}</p>
      <p>Away Team: {game.awayTeam}</p>
      <p>Favorite: {game.favorite}</p>
      <p>Difference: {game.scoreDifference}</p>
      <p>Spread: {game.avgSpread}</p>
      <p className="favorite-line">
         <FontAwesomeIcon icon={faFootballBall} /> Favorite Covering Spread: {game.favoriteCovered}
      </p>
    </div>
  );
};

export default GameCard;
