import React from 'react';
import GameList from './GameList';

const Home = () => {
  return (
    <div className="home-container">
      <aside className="side-panel">
        <div className="record-section">
          <h2>Rick's Season Record Against the Spread</h2>
          <p>Record: 0-0</p>
        </div>
      </aside>
      <main className="main-content">
        <GameList />
      </main>
    </div>
  );
};

export default Home;
