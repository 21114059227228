import React from 'react';
import { useQuery, gql } from '@apollo/client';
import GameCard from './GameCard';  // Import the GameCard component

const GET_INCOMPLETE_GAMES = gql`
  query GetIncompleteGames {
    games(completed: false) {
      id
      startDate
      homeTeam
      awayTeam
      favorite
      completed
      homePoints
      awayPoints
      scoreDifference
      avgSpread
      favoriteCovered
    }
  }
`;

const GameList = () => {
  const { loading, error, data } = useQuery(GET_INCOMPLETE_GAMES);

  // Debugging statements
  console.log('Loading:', loading);
  console.log('Error:', error);
  console.log('Data:', data);
  console.log("Data:", data);

  if (error) {
    console.error('Detailed Error:', JSON.stringify(error, null, 2));
  }

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div className="game-list">
      <h2>This Week's Betting Favorites</h2>
      <ul>
           {data.games.map((game) => (
        <GameCard key={game.id} game={game} />  // Use the GameCard component here
      ))}
      </ul>
    </div>
  );
};

export default GameList;
