import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import gql from 'graphql-tag';
import GameCard from './GameCard';  // Import the GameCard component
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faFootballBall } from '@fortawesome/free-solid-svg-icons';

const GET_COMPLETED_GAMES = gql`
  query GetCompletedGames($page: Int!) {
    games(completed: true, page: $page) {
       id
      startDate
      homeTeam
      awayTeam
      favorite
      completed
      homePoints
      awayPoints
      scoreDifference
      avgSpread
      favoriteCovered
    }
  }
`;

const HistoricalGames = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const { loading, error, data } = useQuery(GET_COMPLETED_GAMES, {
    variables: { page: currentPage },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  return (
    <div className="game-list">
        <h2>Past Games & Results</h2>
        {data.games.map((game) => (
        <GameCard key={game.id} game={game} />       ))}
    <button onClick={prevPage} className="button-gradient" style={{ marginRight: '5px' }}>
      <FontAwesomeIcon icon={faArrowLeft} /> <FontAwesomeIcon icon={faFootballBall} />
    </button>
    <button onClick={nextPage} className="button-gradient">
      <FontAwesomeIcon icon={faFootballBall} /> <FontAwesomeIcon icon={faArrowRight} />
    </button>
    </div>
  );
};


export default HistoricalGames;
